@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
//@import "partials/nav";
@import "partials/intro-section";
// @import "partials/heartbeat";
@import "partials/story-ribbon";
@import "partials/down-button";
@import "partials/countdown";
@import "partials/mediaqueries";
@import "partials/toTop";
@import "partials/404";

body {
    font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
    overflow-x: hidden!important; 
    background-color: white;
    font-family: Montserrat;
}
body, html {
  /* important */
  height: 100%;
}
@media (max-width: 480px) {
  body, html {
    margin: 0px;
    padding: 0px;
  }
  h1.h1 { 
    letter-spacing: 1px;
    font-size: 26px;
    font-family: Montserrat;
  }
  h3.h3 { 
    letter-spacing: 1px;
    font-size: 20px;
  }
}
h1.h1 { 
  font-family: Montserrat;
}
h3.h3 { 
  font-family: Montserrat;
}
hr {
  border-top: 1px solid #888;
}
a {
  color: #333;
  text-decoration: none;
}
a:hover {
  color: #fff;
  border-bottom:2px solid #7FBB00;
}
.active {
  border-bottom:2px solid #7FBB00;
}
a.btn.btn-secondary {
  border: 1px solid black;
}
a.btn.btn-secondary:hover {
  border: 1px solid #7FBB00;
}
.bvi a { color: #fff; }
.brand {
    margin: 0;
}

.center {
    text-align: center;
}

.center a { display: inline; }

.top {
    padding-top: 2em;
}

p {
    font-size: 17px;
    line-height: 1.7em;
    font-weight: normal;
    font-family: Montserrat;
    padding: 5px 0;

}

p.large {
    font-size: 16px;
}
p.lastToTop {
   margin-bottom: -70px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    font-family: Montserrat;
    font-weight: 700;
}

.nav > li > a { 
    padding: 15px; 
}
/* -------------------------------- 

Modules - reusable parts of our design

-------------------------------- */
.cd-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 90%;
  max-width: 1120px;
  margin: 0 auto;
}
.cd-container::after {
  /* clearfix */
  content: '';
  display: table;
  clear: both;
}

.item-separator { 
    background: url(../images/item_separator_heart1.png) center center no-repeat;
    padding: 3em;
}

/* -------------------------------- 

Main components 

-------------------------------- */
.cd-main-content {
  /* you need to assign a min-height to the main content so that the children can inherit it*/
  height: 100%;
  position: relative;
  z-index: 1;
}
.cd-fixed-bg {
  position: relative;
  min-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
}
.cd-fixed-bg h1, .cd-fixed-bg h2 {
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 90%;
  max-width: 1170px;
  text-align: center;
  font-size: 30px;
  font-size: 1.875rem;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  color: white;
}
.cd-fixed-bg.cd-bg-1 {
  background-image: url("../images/jh_engagement_jump_web_overlay.jpg");
}
.cd-fixed-bg.cd-bg-2 {
  background-image: url("../images/bvi2.jpg");
}
.cd-fixed-bg.cd-bg-3 {
  background-image: url("../images/engage4-web.jpg");
  background-position-x: 70%;
}
.cd-fixed-bg.cd-bg-4 {
  background-image: url("../images/jhr_cb_rl1.jpg");  
}
.cd-fixed-bg.cd-bg-5 {
  background-image: url("../images/engage1-web.jpg");
}
.cd-fixed-bg.cd-bg-6 {
  background-image: url("../images/engage3-web.jpg");
  background-position-y: 20%;
}
@media only screen and (min-width: 768px) {
  .cd-fixed-bg h1, .cd-fixed-bg h2 {
    font-size: 36px;
  }
}
@media only screen and (min-width: 768px) {
  .cd-fixed-bg {
    background-attachment: fixed;
  }
  .cd-fixed-bg h1, .cd-fixed-bg h2 {
    font-size: 48px;
    font-weight: 300;
  }
}

.cd-scrolling-bg {
  position: relative;
  padding: 2em 0;
  line-height: 1.6;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.cd-scrolling-bg .our-story {
    margin-top: -4em;
}
.cd-scrolling-bg.cd-color-1 {
  background-color: #3d3536;
  color: #a6989a;
}
.cd-scrolling-bg.cd-color-2 {
  background-color: #fff;
  color: #3d3536;
}
.cd-scrolling-bg.cd-color-3 {
  background-color: #b4d7a8;
  color: #3d3536;
}
.cd-scrolling-bg-footer.cd-color-3 {
  background-color: #3d3536;
  opacity: 0.5;
}


@media only screen and (min-width: 768px) {
  .cd-scrolling-bg {
    padding: 8em 0;
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 2;
    font-weight: 300;
  }
}
#wedding .intro-heading {
    padding-top: 100px;
}
#wedding .intro-heading h2{
    font-size: 0.4em;
}

.pad {
    padding: 5px;
}
.rsvp-form, .rsvp-list {
    background: #eee;  
    border-radius: 10px;
    padding: 1em;
    color: #333;
    font-size: 1.2em;
}
.rsvp-page {
}
.form-center { 
    padding: 4em 0;
}
.form_control {
    height: 39px;
}

.btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus {
    color: #fff;
    background: transparent;
}
.glyphicon { 
    padding-right: 3px;
}
.local {
    padding-bottom: 5px;
    font-size: 1.2em;
}