/* Scroll Top Panel */
#back-top {
	position: fixed;
	bottom: 45px;
	right: 30px;
	z-index: 10;
}
#back-top a {
	width: auto;
	display: block;
	text-align: center;
	font: 10px/100% Arial, Helvetica, sans-serif;
	text-transform: uppercase;
	text-decoration: none;
	border-bottom: 0px; 
	color: #dadada;
}
#back-top a:hover {
	color: #023767;
}
/* arrow icon (span tag) */
#back-top span {
	width: 25px;
	height: 25px;
	display: block;
	margin-bottom: 0;
	background: url(/images/top-arrow.png) no-repeat center center;
}
#back-top a:hover span {
	background-color: none;
}