.cd-fixed-bg .down {
    position: absolute;
    bottom: 10px;
    left: 47%;
    background: transparent;
    color: white;
    font-size: 2.1em;
    border: none;
}

@media (min-width: 1400px) {
    .cd-fixed-bg .down {
        position: absolute;
        bottom: 10px;
        left: 48.5%;
        background: transparent;
        color: white;
        font-size: 2.1em;
        border: none;
    }
}

@media(max-width:767px) {
    .cd-fixed-bg .down {
        position: absolute;
        bottom: 10px;
        left: 44%;
        background: transparent;
        color: white;
        font-size: 2.2em;
        border: none;
    }
}

@media (min-width: 300px) and (max-width: 400px) {
    .cd-fixed-bg .down {
        position: absolute;
        bottom: 10px;
        left: 41%;
        background: transparent;
        color: white;
        font-size: 2.1em;
        border: none;
    }
} 
