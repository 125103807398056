#story {
    background-color: #fff;
    height: auto;
    margin: 0 auto;
    width: 100%;
    position: relative;
}

/*RIBBON*/
@font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon.eot");
    src: url("../fonts/flaticon.eot#iefix") format("embedded-opentype"),
    url("../fonts/flaticon.woff") format("woff"),
    url("../fonts/flaticon.ttf") format("truetype"),
    url("../fonts/flaticon.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
    font-family: Flaticon;
    font-style: normal;
}
.flaticon-rings4:before {
    content: "\e018";
}
.header-ulem {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    color: white;
    margin: 0 auto;
    border: 8px double #333!important;
    position: relative;
    top: 68px;
    z-index: 0;
    background-color: #fff;
}
.header-ulem i {
    font-size: 2.5em;
    text-align: center;
    line-height: 65px;
    color: #333!important;
    border: 2px dotted #333!important;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    position: relative;
    top: 12px;
}

.ribbon-ulem {
    background: #333!important;
    position: relative;
    height: 50px;
    margin: 0 auto;
    padding: 0 45px;
    min-width: 200px;
    display: table;
    /*   border-top: 6px double #E094C7;
    border-bottom: 3px solid #E094C7;*/
}
.ribbon-ulem span {
    font-size: 2em;
    line-height: 50px;
    text-transform: uppercase;
    font-family: Montserrat;
    //font-weight: bold;
    width: 100%;
    color: #fff;
}
.ribbon-ulem span:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    position: absolute;
    background: #fff;
    font-size: 20px;
    top: 20px;
    left: 10px;
}
.ribbon-ulem span:after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    position: absolute;
    background: #fff;
    font-size: 20px;
    top: 20px;
    right: 10px;
}
.ribbon-ulem:before,
.ribbon-ulem:after {
    content: '';
    position: absolute;
    height: 0;
    border: 25px solid transparent;
    top: 0;
    width: 0;
    border-top-color: #333!important;
    border-bottom-color: #333!important;
    z-index: -1;
}
.ribbon-ulem:before {
    left: -25px;
}
.ribbon-ulem:after {
    right: -25px;
}

.story-img img {
    display: block;
    width: 200px;
    height: 200px;
    position: relative;
    border-radius: 50%;
    margin: 0 auto;
    border: 4px solid #eee;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);  
}
@media(max-width:767px) {
    .ribbon-ulem span {
        font-size: 1.3em
    }
}
@media only screen and (min-width: 1170px) {
    .story-img img {
        width: 200px;
        height: 200px;
        left: 50%;
        margin-left: -100px;
    }
}